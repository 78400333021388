import React, { useState, useEffect, useRef } from "react";
import { Card } from "primereact/card";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { Calendar } from "primereact/calendar";
import { Checkbox } from "primereact/checkbox";
import { InputText } from "primereact/inputtext";
import { Loader, Header } from "../components";
import SelectInput from "../components/SelectInput";
import RadioButton from "../components/RadioButton";
import ConfirmPurchaseDialog from '../components/ConfirmPurchaseDialog';
import ProductListDialog from '../components/ProductListDialog';
import ProductPimSelect from "./ProductPimSelect";
import ProductPriceService from "../services/productPrice.services";
import { Dropdown } from "primereact/dropdown";

import "./ProductPricing.css";

const categoryOptions = [
  { value: 'VGA', label: 'VGA' },
  { value: 'Mainboard', label: 'Mainboard' },
  { value: 'CPU', label: 'CPU' }
]

const ProductPricing = () => {

  const toast = useRef(null);

  const [state, setState] = useState({
    loading: true,
    showProductPimSelector: false,
  });

  const [condition, setCondition] = useState("Bình thường");
  const [brandOptions, setBrandOptions] = useState([]);
  const [brand, setBrand] = useState({ id: null, label: 'KXD' });
  const [appearance, setAppearance] = useState("Mới, đẹp");
  const [warrantyDate, setWarrantyDate] = useState(null);
  const [hasBox, setHasBox] = useState(false);
  const [hasFE, setHasFE] = useState(false);
  const [hasAnten, setHasAnten] = useState(false);

  const [priceCondition, setPriceCondition] = useState(0);
  const [priceBrand, setPriceBrand] = useState(0);
  const [priceAppearance, setPriceAppearance] = useState(0);
  const [priceWarranty, setPriceWarranty] = useState(0);

  const [products, setProducts] = useState([]);

  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedPimProduct, setSelectedPimProduct] = useState(null);

  const [totalPrice, setTotalPrice] = useState(0)
  const [bill, setBill] = useState([]);
  const [dialogVisible, setDialogVisible] = useState(false);
  const [productListDialogVisible, setProductListDialogVisible] = useState(false);

  const [productData, setProductData] = useState(null);
  const [productPriceConfig, setProductPriceConfig] = useState(null);

  const [category, setCategory] = useState('CPU');

  const showErr = (msg) => {
    if (toast.current) {
      toast.current.show({
        severity: 'error',
        detail: msg,
        life: 3000,
      });
    }
  };

  useEffect(() => {

    setState({ ...state, loading: true });
    
    const loadProductPriceData = async () => {
      try {
        const dataConfig = await ProductPriceService.getProductPriceConfig(category);
        const data = await ProductPriceService.getProductPriceList(category);
        setProductPriceConfig(dataConfig);
        setProductData(data);

        if (data && data.length > 0) {
          setProducts(data);
          setSelectedProduct(data[0]);
          setSelectedPimProduct(null);
        }

      } catch (error) {
        console.error(error.message)
        showErr(`Không tải được cấu hình giá sản phẩm danh mục ${category}.\n` + error.message);

      } finally {
        setState({ ...state, loading: false });
      }
    };

    loadProductPriceData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [category]);

  useEffect(() => {

    setState({ ...state, loading: true });

    ProductPriceService.getBrandTierList(category)
      .then((responseData) => {
        let newBrandOptions = responseData
          .map((item) => ({
            id: item.id,
            value: item.id,
            label: item.name,
            alias: item.alias,
            tier: item.tier
          }));

        setBrandOptions(newBrandOptions);

        if (newBrandOptions.length > 0) {
          setBrand(newBrandOptions[0])
        }

      })
      .catch((error) => {
        console.error(error);
        showErr(`Lỗi khi lấy thông tin ${category} thương hiệu: ` + error.message);

        setBrandOptions([]);
      })
      .finally(() => {
        setState({ ...state, loading: false });
      })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [category]);

  useEffect(() => {
    setCondition('Bình thường')
  }, [category]);

  useEffect(() => {
    if (selectedProduct && brandOptions.length && productPriceConfig) {
      handleDinhGia();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProduct, condition, brand, appearance, warrantyDate, hasBox, hasFE, hasAnten]);

  const showPrice = (product, condition) => {
    let gia_base = product.gia_base;
    let gia_hu_hong = product.gia_hang_hu_hong;

    switch (condition) {

      case 'Bình thường':
        return gia_base ? gia_base.toLocaleString("en-US") : null;

      case 'Hư, còn xuất hình':
        return gia_hu_hong ? (gia_hu_hong + 100).toLocaleString("en-US") : null;

      case 'Không hoạt động':
      case 'Hư hỏng':
        return gia_hu_hong ? gia_hu_hong.toLocaleString("en-US") : null;

      default:
        return '';
    }
  }

  const isRTX3060OrHigher = (dong) => {
    const rtxRegex = /RTX\s*(\d{4})/i;
    const match = dong.match(rtxRegex);

    if (match) {
      const modelNumber = parseInt(match[1]);
      return modelNumber >= 3060;
    }

    return false;
  }

  const calculateTotal = (basePrice, brand, warranty, appearance, boxRTX3060, feValue, antenValue) => {
    let total = 0;
    total = Math.round(basePrice + brand + warranty + appearance + boxRTX3060 + feValue + antenValue);
    setTotalPrice(total)
  };

  const getWarrantyStatus = () => {
    if (!warrantyDate) return "Hết";

    const expirationDate = new Date(warrantyDate);
    const currentDate = new Date();
    const monthsDifference = (expirationDate.getFullYear() - currentDate.getFullYear()) * 12
      + (expirationDate.getMonth() - currentDate.getMonth());
    const daysDifference = expirationDate.getDate() - currentDate.getDate()

    if (monthsDifference >= 12) {
      return ">= 12 tháng";

    } else if (monthsDifference > 0 || (monthsDifference === 0 && daysDifference >= 0)) {
      return "< 12 tháng";

    } else {
      return "Hết";
    }
  };

  const calculateBill = (product) => {
    if (product && condition) {
      let basePrice = 0

      switch (condition) {

        case 'Bình thường':
          basePrice = product.gia_base ? product.gia_base * 1000 : 0;
          break;

        case 'Hư, còn xuất hình':
          basePrice = product.gia_hang_hu_hong ? (product.gia_hang_hu_hong + 100) * 1000 : 0;
          break;

        case 'Không hoạt động':
        case 'Hư hỏng':
          basePrice = product.gia_hang_hu_hong ? (product.gia_hang_hu_hong) * 1000 : 0;
          break;

        default:
          basePrice = 0;
      }

      calculateTotal(basePrice, 0, 0, 0, 0, 0, 0)

      const billItems = [
        {
          muc: `Giá ${condition}`,
          gia: basePrice.toLocaleString('en-US')
        }
      ];

      if (condition === 'Bình thường') {
        const brandTier = brand?.tier;

        let brandIncrease = 0
        if (category !== 'CPU') {
          if (!brandTier) {
            showErr('Vui lòng chọn 1 thương hiệu hợp lệ !')
            setBill([]);
            return;
          }

          const brandTierValue = productPriceConfig[`Brand ${brandTier.toLowerCase()}`];
          brandIncrease = Math.round(basePrice * (brandTierValue - 1));
        }

        const warrantyStatus = getWarrantyStatus();
        const warrantyValue = productPriceConfig[`Bảo hành ${warrantyStatus.toLowerCase()}`];
        const appearanceValue = productPriceConfig[`Ngoại hình ${appearance.toLowerCase()}`];

        const warrantyIncrease = Math.round(basePrice * (warrantyValue - 1));
        const appearanceIncrease = Math.round(basePrice * (appearanceValue - 1));

        let boxValue = 0
        if (category === 'CPU' && hasBox) {
          boxValue = productPriceConfig['Có box'] * 1000;
        }
        else {
          if (category === 'VGA' && isRTX3060OrHigher(product.dong) && hasBox) {
            boxValue = productPriceConfig['Có box RTX 3060 trở lên'] * 1000;
          }
        }

        let feValue = 0
        let antenValue = 0
        if (category === 'Mainboard') {
          if (hasFE) {
            feValue = productPriceConfig['Có FE'] * 1000;
          }
          if (hasAnten) {
            antenValue = productPriceConfig['Có Anten'] * 1000;
          }
        }

        setPriceCondition(basePrice)
        setPriceBrand(brandIncrease)
        setPriceWarranty(warrantyIncrease)
        setPriceAppearance(appearanceIncrease)
        calculateTotal(basePrice, brandIncrease, warrantyIncrease, appearanceIncrease, boxValue, feValue, antenValue)

        if (category !== 'CPU') {
          billItems.push({
            muc: `Thương hiệu ${brandTier.toLowerCase()}`,
            gia: '+ ' + brandIncrease.toLocaleString("en-US")
          })
        }

        billItems.push(
          {
            muc: `Bảo hành ${warrantyStatus.toLowerCase()}`,
            gia: '+ ' + warrantyIncrease.toLocaleString("en-US")
          },
          {
            muc: `Ngoại hình ${appearance.toLowerCase()}`,
            gia: '+ ' + appearanceIncrease.toLocaleString("en-US")
          }
        );

        if (category === 'CPU' && hasBox) {
          billItems.push({
            muc: 'Có box',
            gia: '+ ' + boxValue.toLocaleString('en-US')
          });
        }
        else {
          if (category === 'VGA' && isRTX3060OrHigher(product.dong) && hasBox) {
            billItems.push({
              muc: `Có box ${product.dong}`,
              gia: '+ ' + boxValue.toLocaleString('en-US')
            });
          }
        }

        if (category === 'Mainboard') {
          if (hasFE) {
            billItems.push({
              muc: 'Có phụ kiện FE',
              gia: '+ ' + feValue.toLocaleString('en-US')
            })
          }

          if (hasAnten) {
            billItems.push({
              muc: 'Có Anten Wifi',
              gia: '+ ' + antenValue.toLocaleString('en-US')
            })
          }
        }
      }
      setBill(billItems);
    }
  };

  const handleDinhGia = () => {
    const missingFields = [];

    if (!condition) missingFields.push("Tình trạng");
    if (!selectedProduct) missingFields.push("Sản phẩm");

    if (condition === 'Bình thường') {
      if (!brand || brand === '__') missingFields.push("Thương hiệu");
      if (!appearance) missingFields.push("Ngoại hình");
    }

    if (missingFields.length > 0) {
      const missingFieldsString = missingFields.join(", ");
      showErr(`Bạn chưa chọn thông tin: ${missingFieldsString}`)
      setBill([]);
      return;
    }

    calculateBill(selectedProduct);
  };

  const onShowProductPimSelector = () => {
    setState({
      ...state,
      showProductPimSelector: true,
      productPriceInfo: {
        selectedPimProduct: selectedPimProduct,
        selectedProduct: selectedProduct,
        condition: condition,
        warranty: getWarrantyStatus(),
        appearance: appearance,
        brand: brand,
        price: totalPrice,
        category: category
      },
    });
  };

  const handleConfirmProductSelection = (newProduct) => {
    if (newProduct !== null) {
      setSelectedProduct(newProduct);
      setHasBox(false);
      setSelectedPimProduct(null);
    }

    setProductListDialogVisible(false);
  };

  const onCloseProductPimSelector = () => {
    setState({ ...state, showProductPimSelector: false });
  };

  const onConfirmProductPimSelector = (value) => {
    setState({ ...state, showProductPimSelector: false });
    setSelectedPimProduct(value);
  };

  if (!productData) {
    return <div>Loading...</div>;
  }

  return (
    <div className="product-pricing">
      {state.loading && <Loader />}
      <Toast ref={toast} className="custom-toast" />
      <Header header={"Định giá sản phẩm"} />

      <div className="product-pricing-content">
        <Card title="Mô tả sản phẩm" style={{ borderRadius: "20px", flex: "0 0 50%" }}>

          <div className="form-group">
            <h3>Danh mục</h3>
            <Dropdown
              value={category}
              options={categoryOptions.sort((a, b) => a.label.localeCompare(b.label))}
              onChange={(e) => {
                setCategory(categoryOptions.find(item => item.value === e.target.value).value)
              }}
              placeholder="Select an option"
              style={{ width: '230px' }}
            />
          </div>

          <div className="form-group">
            <h3>Dòng</h3>
            <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
              <InputText
                id="selected-dong"
                value={selectedProduct ? selectedProduct.dong : ""}
                readOnly
                disabled
                placeholder="Chọn dòng sản phẩm"
                style={{ width: "230px" }}
              />
              <Button
                label="Chọn"
                onClick={() => setProductListDialogVisible(true)}
              />
            </div>
          </div>

          <div className="form-group">
            <h3>Tình trạng</h3>
            <div className="radio-group">
              <RadioButton
                label="Bình thường"
                value="Bình thường"
                checked={condition === "Bình thường"}
                onChange={(e) => setCondition(e.value)}
              />
              {category === 'VGA' ?
                <>
                  <RadioButton
                    label="Hư, còn xuất hình"
                    value="Hư, còn xuất hình"
                    checked={condition === "Hư, còn xuất hình"}
                    onChange={(e) => setCondition(e.value)}
                  />
                  <RadioButton
                    label="Không hoạt động"
                    value="Không hoạt động"
                    checked={condition === "Không hoạt động"}
                    onChange={(e) => setCondition(e.value)}
                  />
                </>
                :
                <RadioButton
                  label="Hư hỏng"
                  value="Hư hỏng"
                  checked={condition === "Hư hỏng"}
                  onChange={(e) => setCondition(e.value)}
                />
              }
            </div>
          </div>

          <div className="form-group">
            <SelectInput
              label="Thương hiệu"
              value={brand?.id}
              onChange={(e) => {
                setBrand(brandOptions.find(item => item.value === e.target.value));
                setSelectedPimProduct(null);
              }}
              options={brandOptions}
            />
          </div>

          <div
            style={{
              visibility: condition !== "Bình thường" ? "hidden" : "visible",
            }}
          >
            <div className="form-group">
              <h3>Bảo hành</h3>
              <Calendar
                value={warrantyDate}
                dateFormat="dd/mm/yy"
                onChange={(e) => setWarrantyDate(e.value)}
                showIcon
                placeholder="Chọn ngày hết hạn bảo hành"
                style={{ width: "300px" }}
              />
            </div>
            <div className="form-group">
              <h3>Ngoại hình</h3>
              <div className="radio-group">
                <RadioButton
                  label="Mới, đẹp"
                  value="Mới, đẹp"
                  checked={appearance === "Mới, đẹp"}
                  onChange={(e) => setAppearance(e.value)}
                />
                <RadioButton
                  label="Trung bình"
                  value="Trung bình"
                  checked={appearance === "Trung bình"}
                  onChange={(e) => setAppearance(e.value)}
                />
              </div>
            </div>

            {(category === 'CPU' ||
              (category === 'VGA' && selectedProduct && isRTX3060OrHigher(selectedProduct.dong)))
              &&
              (<div className="form-group">
                <h3>Có box</h3>
                <Checkbox
                  inputId="hasBox"
                  checked={hasBox}
                  onChange={(e) => setHasBox(e.checked)}
                />
                <label htmlFor="hasBox" className="p-checkbox-label" style={{ marginLeft: '10px' }}>Có box</label>
              </div>)
            }

            {(category === 'Mainboard') &&
              <><div className="form-group">
                <h3>Có phụ kiện FE</h3>
                <Checkbox
                  inputId="hasFE"
                  checked={hasFE}
                  onChange={(e) => setHasFE(e.checked)}
                />
                <label htmlFor="hasFE" className="p-checkbox-label" style={{ marginLeft: '10px' }}>Có FE</label>
              </div>

                <div className="form-group">
                  <h3>Có Anten Wifi</h3>
                  <Checkbox
                    inputId="hasAnten"
                    checked={hasAnten}
                    onChange={(e) => setHasAnten(e.checked)}
                  />
                  <label htmlFor="hasAnten" className="p-checkbox-label" style={{ marginLeft: '10px' }}>Có Anten</label>
                </div></>
            }
          </div>
        </Card>

        {bill.length === 0 && (
          <div
            className="bill"
            style={{ visibility: bill?.length > 0 ? "visible" : "hidden" }}
          >
            <h3>Vui lòng nhập mô tả sản phẩm !</h3>
          </div>
        )}

        <div
          className="bill"
          style={{ visibility: bill?.length > 0 ? "visible" : "hidden" }}
        >
          <h3>Tổng giá trị sản phẩm - {selectedProduct?.dong}</h3>
          <table>
            <thead>
              <tr>
                <th>Mục</th>
                <th>Giá (VNĐ)</th>
              </tr>
            </thead>
            <tbody>
              {bill.map((item, index) => (
                <tr key={index}>
                  <td>{item.muc}</td>
                  <td>{item.gia}</td>
                </tr>
              ))}
              <tr>
                <td>
                  <strong>Tổng</strong>
                </td>
                <td><strong>{totalPrice.toLocaleString("en-US")}</strong></td>
              </tr>
              <tr>
                <td>
                  <strong>SKU</strong>
                </td>
                <td>
                  <div
                    style={{
                      display: "flex",
                      gap: "10px",
                      alignItems: "center",
                      justifyContent: "end",
                    }}
                  >
                    {selectedPimProduct
                      ? selectedPimProduct.product_sku
                      : "Chưa xác định"}
                    <Button
                      label={selectedPimProduct ? "Cập nhật" : "Chọn "}
                      severity="success"
                      icon="pi pi-search"
                      onClick={onShowProductPimSelector}
                    />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <div
            style={{ margin: "auto", width: "fit-content", marginTop: "20px" }}
          >
            <Button
              label="Xác nhận mua"
              className="p-button-primary"
              onClick={() => {
                selectedPimProduct
                  ? setDialogVisible(true)
                  : showErr("SKU chưa xác định !")
              }}
            />
          </div>
        </div>
      </div>

      {state.showProductPimSelector && (
        <ProductPimSelect
          productPriceInfo={state.productPriceInfo}
          onCancel={onCloseProductPimSelector}
          onConfirm={onConfirmProductPimSelector}
          brandOptions={brandOptions}
        />
      )}
      <ConfirmPurchaseDialog
        visible={dialogVisible}
        onHide={() => setDialogVisible(false)}
        productData={{
          pim_product_id: selectedPimProduct?.product_id,

          category: category,
          dong: selectedProduct ? selectedProduct.dong : null,
          product_status: condition,
          product_appearance: appearance,
          product_brand: brand.label,
          warranty_date: warrantyDate,

          price_status: priceCondition,
          price_tier: priceBrand,
          price_warranty: priceWarranty,
          price_appearance: priceAppearance,

          total_price: totalPrice,

          unit: 'Cái',
          qty: 1,
        }}
      />

      <ProductListDialog
        category={category}
        visible={productListDialogVisible}
        products={products}
        showPrice={showPrice}
        onSelectProduct={handleConfirmProductSelection}
      />
    </div>
  );
};

export default ProductPricing;
